<template>
  <div class="wrapper" :class="{'nav-hide': navHide }">
    <!-- purple紫 azure蓝 green绿 orange橙 danger红 rose玫瑰 -->
    <div class="sidebar" data-color="azure" data-background-color="black">
      <div class="logo">
        <a href="#" class="simple-text logo-mini">RS</a>
        <a href="#" class="simple-text logo-normal">Redscn</a>
      </div>
      <div class="sidebar-wrapper">
        <div class="user">
          <div class="user-info">
            <a data-toggle="collapse" href="#user">
              <span>
                {{ member.username }}
                <b class="caret"></b>
              </span>
            </a>
            <div class="collapse" id="user">
              <ul class="nav">
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    <span class="sidebar-normal">个人信息</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#" @click="logout()">
                    <span class="sidebar-normal">退出登陆</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <rs-nav :nav="$router.options.routes"></rs-nav>
      </div>
    </div>
    <div class="main-panel">
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      navHide: false,
      member: { username: '' }
    }
  },
  methods: {
    minibarShow () {
      this.minibar = !this.minibar
    },
    logout () {
      // 登出
      localStorage.clear()
      window.location.href = '/login?redirect=' + this.$route.path
    }
  },
  mounted () {
    // 获取用户信息
    console.log(this.$router.options.routes)

    this.$api.user.index(
      null,
      (data) => {
        this.member = data.data
      }
    )
  }
}
</script>

<style lang="scss">
// 详情隐式编辑css
.card:hover .edit {
  display: flex !important;
}
.nav-hide {
  transform: none !important;
}
.modal .modal-dialog{
  margin-top:0 !important;
}

@media (min-width: 576px){
  .modal-dialog {
    max-width: 800px !important;
  }
}
</style>
